<template>
  <div class="fragment">

    <div
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['fbm_addWeightScale'])"></div>
      <MainButton
          v-if="!foundDevice"
          class="mb-3 wfc"
          :value="$t('fbm_addWeightScale.localization_value.value')"
          @click.native="addWeightScaleDevice"
      />
    </div>
    <div class="order-create__product custom-row">

      <div class="order-create__col custom-col custom-col--33 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate('fbm_DimentionsWeightLB')"></div>
        <DefaultInput
            v-bind:class="{'ui-no-valid': MR.data.Dimensions.validation.weightLB}"
            :error="MR.data.Dimensions.validation.weightLB"
            :errorTxt="$t(`${MR.data.Dimensions.validationTranslate.weightLB}.localization_value.value`)"
            :label="$t('fbm_DimentionsWeightLB.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-model="MR.data.Dimensions.data.weightLB"
            :autocompleteOff="true"
            @input.native="acceptNumber($event, 'weightLB')"
            @blur="$emit('dimensionsChanged')"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate('fbm_DimentionsWeightOZ')"></div>
        <DefaultInput
            v-bind:class="{'ui-no-valid': MR.data.Dimensions.validation.weightOZ}"
            :error="MR.data.Dimensions.validation.weightOZ"
            :errorTxt="$t(`${MR.data.Dimensions.validationTranslate.weightOZ}.localization_value.value`)"
            :label="$t('fbm_DimentionsWeightOZ.localization_value.value')"
            :type="'text'"
            :placeholder="'e.g. 10'"
            v-model="MR.data.Dimensions.data.weightOZ"
            :autocompleteOff="true"
            @input.native="acceptNumber($event, 'weightOZ')"
            @blur="$emit('dimensionsChanged')"
        />
      </div>

    </div>
  </div>

</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "Dimensions",
  components: {
    DefaultInput,
    MainButton
  },

  props: {
    MR: Object,
  } ,

  data() {
    return {
      weightScaleLB: 0,
      weightScaleOZ: 0,
      foundDevice: false,
      productId: '',
      deviceDataArr: [],
    }
  },

  async mounted() {
    if(navigator.hid) {
      await navigator.hid.getDevices().then(async devices => {
        let device = this._.find(devices, {vendorId: 2338})
        if(!device) return
        this.foundDevice = true
        await device.open().then(() => {
          this.changeWeightScale(device)
        })
      })
    }

  },

  destroyed() {
    if(navigator.hid) {
      navigator.hid.getDevices().then(devices => {
        let device = this._.find(devices, {vendorId: 2338})
        if(device) {
          device.close();
        }
      })
    }
  },

  methods: {

    addWeightScaleDevice() {
      const vendorId = 0x0922;
      navigator.hid.requestDevice({ filters: [{ vendorId }] })
          .then(devices => {
            if(devices.length > 0) {
              this.foundDevice = true
              devices.map(device => {
                return device.open()
                    .then(() => {
                      this.changeWeightScale(device)
                    })
              })
            }
          })
          .catch(error => {
            console.log(error);
          });
    },

    changeWeightScale(device) {
      device.addEventListener("inputreport", (event) => {
        const { data, device } = event;
        let buffArray = new Uint8Array(data.buffer);
        // console.log(buffArray);

        this.productId = device?.productId
        this.deviceDataArr = buffArray

        let result = this.getScaleDecodeData(buffArray)

        this.weightScaleLB = result.weightScaleFirst
        this.weightScaleOZ = result.weightScaleSecond
      })
    },


    acceptNumber(event, type) {
      if(this.foundDevice && event.target.value === ' ') {
        this.MR.data.Dimensions.data.weightLB = this.weightScaleLB
        this.MR.data.Dimensions.data.weightOZ = this.weightScaleOZ

        // let scale = {
        //   first: this.type === 'metric' ? 'kg' : 'lb',
        //   second: this.type === 'metric' ? 'gr' : 'oz',
        // }

        // let error = {
        //   stack: `weightScale = ${this.weightScaleLB}${scale.first} ${this.weightScaleOZ}${scale.second};  productId = ${this.productId};
        //     deviceDataArr =
        //     [0]: ${this.deviceDataArr[0]};
        //     [1]: ${this.deviceDataArr[1]};
        //     [2]: ${this.deviceDataArr[2]};
        //     [3]: ${this.deviceDataArr[3]};
        //     [4]: ${this.deviceDataArr[4]}.`
        // }
        // this.createErrorLog(error)
        return
      }

      switch (type) {
        case 'weightLB':
          this.MR.data.Dimensions.data.weightLB = event.target.value.replace('.', '').replace(',', '')
          break
        case 'weightOZ':
          this.MR.data.Dimensions.data.weightOZ = event.target.value.replace('.', '').replace(',', '')
          break
        default:
          break
      }
    },
  }

}
</script>

<style scoped>

</style>