
export const orderMeestMixin = {

  methods: {
    save(edit = false) {
      if (!this.MR.firstValidation(this.isAdmin)) return

      let data = this.MR.prepareSave(this.isAdmin, edit)

      let typeDispatch = 'createReturnGoodsDocuments'

      if (edit) {
        typeDispatch = 'updateReturnGoodsDocuments'
        data = {
          id: this.MR.data.Order.getId(),
          data: data,
        }
      }

      this.$store.dispatch(typeDispatch, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.openNotify('success', 'common_notificationRecordChanged')
            this.$router.push(this.$store.getters.GET_PATHS.ordersMeestReturns)
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },
  },

}