import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Order} from "@/components/globalModels/Order";
import moment from 'moment';
import {User} from "@/components/globalModels/User";
import {Dimensions} from "@/components/globalModels/Dimensions";
import {ProductForCell} from "@/components/globalModels/ProductForCell";
import _ from "lodash";
import {Product} from "@/components/globalModels/Product";
import {ProductCells} from "@/components/globalModels/ProductCells";
import {MEEST_STATUSES, MEEST_STATUSES_ARRAY} from "@/staticData/staticVariables";

export function MeestReturns() {

  this.checkValid = checkValid

  this.data = {
    User: new User(),
    Order: new Order(),
    status: null,
    customerName: '',
    comment: '',
    sendDate: '',
    shippedDate: '',
    trackingNumber: '',
    Dimensions: new Dimensions(),
    price: '',
    productsForCellArray: [new ProductForCell()],
    productsDefault: [new Product()],
  }

  this.validation = {
    comment: false,
    shippedDate: false,
    trackingNumber: false,
    status: false,
    sendDate: false,
    price: false,
  }

  this.validationTranslate = {
    comment: '',
    shippedDate: '',
    trackingNumber: '',
    status: '',
    sendDate: '',
    price: '',
  }

  this.validationTxt = {
    comment: false,
    shippedDate: false,
    trackingNumber: false,
    status: false,
    sendDate: false,
    price: false,
  }

  /**
   * Getters
   */
  this.getComment = () => {
    return this.data.comment
  }
  this.getCustomerName = () => {
    return this.data.customerName
  }
  this.getShippedDate = () => {
    return this.data.shippedDate
  }
  this.getTrackingNumber = () => {
    return this.data.trackingNumber
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getSendDate = () => {
    return this.data.sendDate
  }
  this.getPrice = () => {
    return this.data.price
  }

  /**
   * Setters
   */
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setCustomerName = (val) => {
    this.data.customerName = val
  }
  this.setShippedDate = (val) => {
    this.data.shippedDate = val
  }
  this.setTrackingNumber = (val) => {
    this.data.trackingNumber = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setSendDate = (val) => {
    this.data.sendDate = val
  }
  this.setPrice = (val) => {
    this.data.price = val
  }
}

/**
 * Functions
 */


MeestReturns.prototype.productsDefaultValidation = function (isNotUSACountry) {
  let valid = true
  this.data.productsDefault.map(item => {
    if (!item.productValidate({productObject: true, count: true, description: isNotUSACountry, price: isNotUSACountry}))
      valid = false
  })
  return valid
}

MeestReturns.prototype.setProductsDefault = function (val) {
  if(val === ''){
    this.data.productsDefault = [new Product()]
  }
}

MeestReturns.prototype.addProduct = function () {
  this.data.productsDefault.push(new Product)
  return this.data.productsDefault.length - 1
}

MeestReturns.prototype.addProductForCells = function (product = false, cells = false, productEntity = false) {
  let productForCell = new ProductForCell(product, false, false, productEntity?.count_scanned_kyiv)
  if(cells.length > 0){
    productForCell.setCellsByType(cells)
  }
  this.data.productsForCellArray.push(productForCell)
  return this.data.productsForCellArray.length - 1
}

MeestReturns.prototype.removeProductForCells = function (array, index) {
  array.splice(index, 1)
}


MeestReturns.prototype.sortCellArrayByStorageCount = function (userCellArray) {
  let sortByStorageCount = _.sortBy(userCellArray, function (item) {
    return 0 - item.data.storageCount
  })
  return sortByStorageCount
}

MeestReturns.prototype.createProductModelAndCellsArray = function (productEntity, item) {
  /**
   * create and Product model
   * @type {Product}
   */
  let newProduct = new Product()
  newProduct.setProduct(productEntity.product, {
    id: productEntity.product.id,
    name: productEntity.product.name,
    count: productEntity.item_quantity,
    productModelId: item.id,
    description: productEntity.item_description,
    price: productEntity.item_price,
    hs_code: productEntity.hs_code,
  })

  let cells = []
  productEntity.cells.map(cellItem => {
    let cellOptions = productEntity.cells.map(cell => {return cell.cell})
    let cell = new ProductCells(cellOptions, cellItem.cell, false, cellItem.item_quantity)
    cells.push(cell)
  })

  let existCell = cells.length > 0
  /**
   * Map product -> items_in_storage
   * if exist cell in storage and not selected =>
   * add this cell to productsForCellArray
   */
  productEntity.product?.items_in_storage.map(productInWarehouse => {
    if(productInWarehouse.damage) return

    /**
     * if this cell will be selected and saved
     * go to next productInWarehouse
     */
    if(existCell){
      let findExistCell = _.find(cells, function (findItem) {
        return findItem.data.cellSelected?.id === productInWarehouse.cell_id
      })
      /**
       * set storageCount from items_in_storage[index]
       */
      if(findExistCell) {
        findExistCell.data.storageCount = productInWarehouse.quantity
        return
      }
    }

    /**
     * set cellOptions in array productEntity.product?.items_in_storage
     */
    let cellOptions = _.filter(productEntity.product?.items_in_storage, function(cell) {
      if(productInWarehouse?.cell?.mixed && cell?.cell?.mixed) return cell.cell
      if(!productInWarehouse?.cell?.mixed && (!cell?.cell || !cell?.cell?.mixed)) return cell.cell
    })
    cellOptions = cellOptions.map(item => {return item.cell})

    let cell = new ProductCells(
      cellOptions,
      productInWarehouse.cell,
      productInWarehouse.quantity,
      false
    )

    cells.push(cell)
  })

  return {newProduct, cells, existCell}
}


MeestReturns.prototype.addProductCountWithoutCell = function (product, productForCell) {
  product.product.items_in_storage.map(productInWarehouse => {
    if(!productInWarehouse.cell && productInWarehouse.cell_id) return
    if(productInWarehouse.damage) return

    if(!productInWarehouse.cell) {
      productForCell.data.withoutCellDeclaredCounts = parseInt(productForCell.data.withoutCellDeclaredCounts) + parseInt(productInWarehouse.quantity)
    }
  })
}

MeestReturns.prototype.addProductExistCountWithoutCell = function (product, productForCell) {
  product.cells.map(cell => {
    if(!cell.cell && cell.cell_id) return

    if(!cell.cell) {
      productForCell.data.withoutCellEndCounts = parseInt(productForCell.data.withoutCellEndCounts) + parseInt(cell.item_quantity)
    }
  })
}

MeestReturns.prototype.productsDataGeneration = function (array, withCells = false) {
  let data = []
  array.map(item => {
    let product = item.data.Product.product
    let productId = product?.id

    if(!productId) return

    let itemData = {
      "product_id": productId,
      // "hs_code": item.data.Product.getHsCode(),
      "item_quantity": item.data.Product.getProductCount(),
      // "item_description": item.data.Product.getProductDescription(),
      // "item_price": parseFloat(item.data.Product.getProductPrice()) > 0 ? parseFloat(item.data.Product.getProductPrice()) : null,
      // "id": item.data.Product.getProductModelId(),
      "id": item.data.Product.getProductId(),
    }

    if(withCells){
      let cells = [],
        userCellArray = item.data.UserCellArray,
        mixedCellArray = item.data.MixedCellArray

      cells = [...cells, ...this.getCellsByArray(userCellArray, productId)]
      cells = [...cells, ...this.getCellsByArray(mixedCellArray, productId)]

      if(parseInt(item.data.withoutCellEndCounts) !== 0) {
        cells.push({
          "product_id": productId,
          "cell_id": null,
          "item_quantity": parseInt(item.data.withoutCellEndCounts),
        })
      }


      itemData["cells"] = cells
    }
    data.push(itemData)
  })
  return data
}

MeestReturns.prototype.getCellsByArray = function (array, productId) {
  let cells = []
  array.map(itemCell => {
    if(itemCell.data.cellCount > 0){
      cells.push({
        "product_id": productId,
        "cell_id": itemCell.data.cellSelected.id,
        "item_quantity": parseInt(itemCell.data.cellCount),
      })
    }
  })
  return cells
}

MeestReturns.prototype.setProductsForCellArray = function (productEntities, item) {
  productEntities.map((productEntity) => {

    let {
      newProduct,
      cells,
      existCell
    } = this.createProductModelAndCellsArray(productEntity, item)

    console.log(newProduct);
    /**
     * set product to ProductForCell model
     * and add to productsForCellArray array
     */
    let newIndex = this.addProductForCells(newProduct, cells, productEntity)

    /**
     * Add Product Count Without Cell
     */
    this.addProductCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])

    /**
     * Add Product Exist Count Without Cell
     */
    this.addProductExistCountWithoutCell(productEntity, this.data.productsForCellArray[newIndex])

    /**
     * Sort ProductsForCellArray by storage quantity
     */
    if(!existCell){
      return
      // let userArray = this.data.productsForCellArray[newIndex].data.UserCellArray
      // this.data.productsForCellArray[newIndex].data.UserCellArray = this.sortCellArrayByStorageCount(userArray)
    }

  })
}


/**
 * Global Setters
 */

MeestReturns.prototype.setItem = function (item) {
  let productEntities = item.product_entities

  if(item.user) {
    this.data.User.setUser(item['user'])
  }
  this.data.Order.setOrderOBJ(item)
  this.data.Order.setId(item.id)
  this.data.Order.setTrackingNum(item.tracking_number ? item.tracking_number : '')
  this.data.Order.setStatus(item.status ? _.find(MEEST_STATUSES_ARRAY, {value: item.status}) : '')
  this.data.Order.setWarehouse(item.warehouse_storage)
  this.setShippedDate(item.delivery_date ? item.delivery_date : '')
  this.setSendDate(item.received_at ? item.received_at : '')
  this.setComment(item.comment ? item.comment : '')
  this.setCustomerName(item.customer_name ? item.customer_name : '')
  this.setPrice(item.order_price ? parseFloat(item.order_price).toFixed(2) : '0')
  this.data.Dimensions.setDimensionsWeight(item.weight ? item.weight : '')
  this.data.Dimensions.setDimensionsWeightLB(item?.order_dimension.weightLb ? item.order_dimension.weightLb : '')
  this.data.Dimensions.setDimensionsWeightOZ(item?.order_dimension.weightOz ? item.order_dimension.weightOz : '')

  /**
   * set ProductsForCellArray
   */
  this.data.productsForCellArray = []
  this.setProductsForCellArray(productEntities, item)
}

/**
 * Prepare Data
 */

MeestReturns.prototype.prepareSave = function (isAdmin = false, edit = false) {
  let data =  {
    'user_id': this.data.User.getUserId(),
    'storage_id': this.data.Order.getWarehouse()?.id ? this.data.Order.getWarehouse().id : null,
    'comment': this.getComment(),
    'received_at': this.getSendDate() ? moment(this.getSendDate()).format('YYYY-MM-DD') : null,
    'delivery_date': this.getShippedDate() ? moment(this.getShippedDate()).format('YYYY-MM-DD') : '',
    'tracking_number': this.data.Order.getTrackingNum(),
    'status': this.data.Order.getStatus()?.value,
    // 'price': this.getPrice(),
    'customer_name': this.getCustomerName(),
    'weight': this.data.Dimensions.getDimensionsWeight(),
  }

  data['order_dimension'] = {
    weightLb: this.data.Dimensions.getDimensionsWeightLB() ? this.data.Dimensions.getDimensionsWeightLB() : null,
    weightOz: this.data.Dimensions.getDimensionsWeightOZ() ? this.data.Dimensions.getDimensionsWeightOZ() : null,
  }

  let products = this.data.productsForCellArray
  data["product_data"] = this.productsDataGeneration(products, isAdmin && edit)

  return data
}

/**
 * Validations
 */

MeestReturns.prototype.firstValidation = function (isAdmin) {
  let valid = true

  let validationItems = {
    // customerName: this.getCustomerName(),
    // shippedDate: this.getShippedDate(),
    // sendDate: this.getSendDate(),
  }

  let validationOptions = {
    // customerName: {type: ['empty']},
    // shippedDate: {type: ['empty']},
    // sendDate: {type: ['empty']},
  }

  if(!this.data.User.userValidation({userId: true})) {
    valid = false
  }

  console.log(this.data.Order.getStatus()?.value);
  if(this.data.Order.getStatus()?.value === MEEST_STATUSES.MEEST_STATUS_RECEIVED.value) {
    validationItems['shippedDate'] = this.getShippedDate()
    validationOptions['shippedDate'] = {type: ['empty']}
  }

  console.log(this.data.Order.getOrderOBJ()?.return_parcel);
  if(!this.data.Order.getOrderOBJ()?.return_parcel) {
    this.data.productsForCellArray.map(item => {
      if (!item.data.Product.productValidate({productObject: true, count: true, description: false, price: false, hsCode: false})){
        valid = false
      }
    })
  }

  if(isAdmin && !this.data.Dimensions.dimensionsPartialValidate({weightLB: true, weightOZ: true,})) {
    valid = false
  }


  return (this.checkValid(validationItems, validationOptions)) && valid
}
