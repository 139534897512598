<template>
  <div class="fragment">

    <div class="order-create__section">
      <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
        <div class="custom-col custom-col--33 custom-col--md-100">
          <UserSelect
              :validationUser="MR.data.User.validation.userId"
              :validationTxtUser="MR.data.User.validationTxt.userId"
              :validationTranslateUser="MR.data.User.validationTranslate.userId"
              :userSelected="MR.data.User.user"
              @changeUser="changeUser"
              :typeSelect="'users'"
              :userEmail="true"
              :copyName="true"
          />
        </div>

        <div class="custom-col custom-col--33 custom-col--md-100" v-if="isAdmin">
          <MainButton class="btn-fit-content ml-auto"
                      :value="$t('common_termalPrint.localization_value.value')"
                      @click.native="massThermalPrint"
          />
        </div>
      </div>


    </div>

    <div class="custom-row" >
      <div class="address-editing__col custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['apcDelcon_sendDate'])"></div>
        <DatePickerDefault
            :label="$t('apcDelcon_sendDate.localization_value.value')"
            v-bind:class="{'ui-no-valid': MR.validation.sendDate}"
            :errorTxt="$t(`${MR.validationTranslate.sendDate}.localization_value.value`)"
            :error="MR.validation.sendDate"
            v-model="MR.data.sendDate"
        >
          <template slot="body">
            <date-picker
                v-model="MR.data.sendDate"
                ref="date"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="'YYYY-MM-DD'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="address-editing__col custom-col custom-col--33 custom-col--xs-100"
           v-if="edit && isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_shipped'])"></div>
        <DatePickerDefault
            :label="$t('returnGoods_shipped.localization_value.value')"
            v-bind:class="{'ui-no-valid': MR.validation.shippedDate}"
            :errorTxt="$t(`${MR.validationTranslate.shippedDate}.localization_value.value`)"
            :error="MR.validation.shippedDate"
            v-model="MR.data.shippedDate"
        >
          <template slot="body">
            <date-picker
                v-model="MR.data.shippedDate"
                ref="date"
                valueType="format"
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'YYYY-MM-DD'"
                :placeholder="'YYYY-MM-DD'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>
    </div>

<!--    <div class="custom-row">-->
<!--      <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;xs-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['consolidations_customerName'])"></div>-->
<!--        <div class="section-label">-->
<!--          <DefaultInput-->
<!--              :label="$t('consolidations_customerName.localization_value.value')"-->
<!--              :type="'text'"-->
<!--              v-bind:class="{'ui-no-valid': MR.validation.customerName}"-->
<!--              :errorTxt="$t(`${MR.validationTranslate.customerName}.localization_value.value`)"-->
<!--              :error="MR.validation.customerName"-->
<!--              v-model="MR.data.customerName"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="custom-row" v-if="edit && isAdmin">
      <div class="custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_trackingNumber'])"></div>
        <div class="section-label">
          <DefaultInput
              :label="$t('returnGoods_trackingNumber.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': MR.data.Order.validation.trackingNumber}"
              :errorTxt="$t(`${MR.data.Order.validationTranslate.trackingNumber}.localization_value.value`)"
              :error="MR.data.Order.validation.trackingNumber"
              v-model="MR.data.Order.data.trackingNum"
          />
        </div>
      </div>

      <div class="custom-col custom-col--33 custom-col--xs-100"
           v-if="MR.data.Order.data.orderOBJ && MR.data.Order.data.orderOBJ.return_document_union && MR.data.Order.data.orderOBJ.return_document_union.tracking_number"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_meestTrackingNumber'])"></div>
        <div class="section-label">
          <DefaultInput
              :label="$t('returnGoods_meestTrackingNumber.localization_value.value')"
              :type="'text'"
              :value="MR.data.Order.data.orderOBJ.return_document_union.tracking_number"
              :disabled="true"
          />
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="custom-col">

        <template v-if="isAdmin || (!MR.data.Order.data.orderOBJ || (MR.data.Order.data.orderOBJ && !MR.data.Order.data.orderOBJ.return_parcel))">
          <div class="custom-row">
            <div class="custom-col">
              <div class="d-flex">
                <RadioDefault
                    class="white-space-line width-initial mr-3"
                    v-for="(item, index) in warehouses"
                    :key="index"
                    :label="item.name"
                    :name="'warehouse'"
                    :value="MR.data.Order.data.warehouse && item.id === MR.data.Order.data.warehouse.id"
                    :disabled="(edit && isAdmin) || (!isAdmin && item.id !== 1)"
                    @input="changeWarehouse(item)"
                />
              </div>
            </div>
          </div>
        </template>

        <div class="custom-row" v-if="isAdmin">
          <div class="custom-col custom-col--50 custom-col--md-100 mb-0"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['fbm_scannerHere'])"></div>
            <div class="section-label">
              <DefaultInput
                  ref="fbmScanInput"
                  :label="$t('fbm_scannerHere.localization_value.value')"
                  :type="'text'"
                  v-model="scan"
                  :autocompleteOff="true"
                  @change="changeScanner"
              />

            </div>
          </div>
        </div>

        <ProductsAdminBlock
            v-if="edit && isAdmin && MR.data.Order.data.warehouse"
            :OrderModel="MR"
            :productsForCellArray="MR.data.productsForCellArray"
            :OrderUser="MR.data.User"
            :productLabel="'name_for_fbm'"
            :products="MR.data.productsDefault"

            :count="false"
            :price="false"
            :description="false"
            :hsCode="false"
            :byStorageId="MR.data.Order.data.warehouse.id"

            :showProductCheckbox="true"

            @removeProduct="index => removeProduct(null, index)"
            @changeProductName="changeProductName"
            @changeProductQuantity="changeProductQuantity"
            @addProduct="addDefaultProduct"

            @recalculateFees="$emit('recalculateFees')"
        />

<!--        <ProductsAdminBlock-->
<!--            v-if="edit && isAdmin"-->
<!--            :MR="MR"-->
<!--            :user="MR.data.User"-->
<!--        />-->

        <div class="fragment" v-else
             v-bind:class="{'loading-products': productLoading}">
          <SelectProductBlock
              v-for="(item, index) in MR.data.productsForCellArray"
              :autoloadSelect="true"
              :initialOptionsProducts="initialOptionsProducts"
              :key="index+'product'"
              :item="item.data.Product"
              :userId="parseInt(MR.data.User.user.userId)"
              :productLabel="'name_for_fbm'"
              :price="false"
              :description="false"
              :hsCode="false"
              @removeProduct="removeProduct(MR.data.productsForCellArray, index)"
              @changeProductName="changeProductName"
              @changeProductQuantity="changeProductQuantity"
          />
        </div>
      </div>

      <template v-if="!MR.data.Order.data.orderOBJ || (MR.data.Order.data.orderOBJ && !MR.data.Order.data.orderOBJ.return_parcel)">
        <div class="custom-col" v-if="!edit || !isAdmin">
          <div class="order-create__product-btn add-product-btn-wrap"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
            <span class="order-create__product-btn-i add-product-btn site-link"
                  @click="MR.addProductForCells()">
            + {{$t('common_AddProduct.localization_value.value')}}
            </span>
          </div>
        </div>
      </template>

    </div>

    <Dimensions
        v-if="isAdmin"
        :MR="MR"
    />

<!--    <div class="custom-row"  v-if="isAdmin">-->
<!--      <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;xs-100"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWeightKG'])"></div>-->
<!--        <DefaultInput-->
<!--            v-bind:class="{'ui-no-valid': MR.data.Dimensions.validation.weight}"-->
<!--            :error="MR.data.Dimensions.validation.weight"-->
<!--            :errorTxt="$t(`${MR.data.Dimensions.validationTranslate.weight}.localization_value.value`)"-->
<!--            :label="$t('fbm_DimentionsWeightKG.localization_value.value') "-->
<!--            :type="'text'"-->
<!--            :placeholder="'e.g. 10'"-->
<!--            v-model="MR.data.Dimensions.data.weight"-->
<!--            :autocompleteOff="true"-->
<!--        />-->
<!--      </div>-->



<!--&lt;!&ndash;      <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;xs-100"&ndash;&gt;-->
<!--&lt;!&ndash;           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="admin-edit" @click="editTranslate(['fbm_DimentionsWeightGR'])"></div>&ndash;&gt;-->
<!--&lt;!&ndash;        <DefaultInput&ndash;&gt;-->
<!--&lt;!&ndash;            v-bind:class="{'ui-no-valid': MR.data.Dimensions.validation.weightOZ}"&ndash;&gt;-->
<!--&lt;!&ndash;            :error="MR.data.Dimensions.validation.weightOZ"&ndash;&gt;-->
<!--&lt;!&ndash;            :errorTxt="$t(`${MR.data.Dimensions.validationTranslate.weightOZ}.localization_value.value`)"&ndash;&gt;-->
<!--&lt;!&ndash;            :label="$t('fbm_DimentionsWeightGR.localization_value.value')"&ndash;&gt;-->
<!--&lt;!&ndash;            :type="'text'"&ndash;&gt;-->
<!--&lt;!&ndash;            :placeholder="'e.g. 10'"&ndash;&gt;-->
<!--&lt;!&ndash;            v-model="MR.data.Dimensions.data.weightOZ"&ndash;&gt;-->
<!--&lt;!&ndash;            :autocompleteOff="true"&ndash;&gt;-->
<!--&lt;!&ndash;        />&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->

    <div class="order-create__row custom-row" v-if="isAdmin">
      <div class="custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_price'])"></div>
        <InputSum
            :label="$t('returnGoods_price.localization_value.value')"
            :icoType="'dollar'"
            :disabled="true"
            v-model="MR.data.price"
        />
      </div>
      <div class="custom-col custom-col--33 custom-col--xs-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_status'])"></div>
        <div class="section-label">
          <DefaultSelect
              :options="MEEST_STATUSES_ARRAY"
              :label="$t('returnGoods_status.localization_value.value')"
              :otherValue="'translation'"
              :selected="MR.data.Order.data.status"
              :clearable="false"
              @change="changeStatus"
          />
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['returnGoods_comment'])"></div>
        <TextareaDefault
            :label="$t('returnGoods_comment.localization_value.value')"
            v-bind:class="{'ui-no-valid': MR.validation.comment}"
            :errorTxt="$t(`${MR.validationTranslate.comment}.localization_value.value`)"
            :error="MR.validation.comment"
            v-model="MR.data.comment"
        />
      </div>
    </div>

  </div>
</template>

<script>
import DatePickerDefault from "@/components/UI/inputs/DatePickerDefault/DatePickerDefault";
import DatePicker from 'vue2-datepicker'
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import TextareaDefault from "@/components/UI/textarea/TextareaDefault/TextareaDefault";
import {filterFunc} from "@/mixins/filterFuncMixin/filterFunc";
import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";
import UserSelect from "@/components/coreComponents/UserSelect/UserSelect";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import SelectProductBlock from "@/components/coreComponents/SelectProductBlock/SelectProductBlock";
import InputSum from "@/components/UI/inputs/InputSum/InputSum";
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import {MEEST_STATUSES_ARRAY} from "@/staticData/staticVariables";
import ProductsAdminBlock
  from "@/components/coreComponents/ProductCellsManageForOrders/ProductsAdminBlock/ProductsAdminBlock";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";
import Dimensions from "@/components/modules/OrdersMeestModule/chunks/Dimensions/Dimensions";
// import ProductsAdminBlock from "@/components/modules/OrdersMeestModule/chunks/ProductsAdminBlock/ProductsAdminBlock";

export default {
  name: "FieldsBlockMeest",
  components: {
    Dimensions,
    MainButton,
    ProductsAdminBlock,
    RadioDefault,
    InputSum,
    SelectProductBlock,
    DefaultSelect,
    UserSelect,
    TextareaDefault,
    DefaultInput,
    DatePickerDefault,
    DatePicker,
  },

  mixins: [filterFunc, returnGoodsMixin],

  props: {
    MR: Object,
    edit: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    getUser() {
      return this.MR.data.User.user
    }
  },

  watch: {
    getUser() {
      this.getWarehouseStorage()
    }
  },

  data() {
    return {
      options: [],
      initialOptionsProducts: [],
      warehouses: [],
      MEEST_STATUSES_ARRAY: MEEST_STATUSES_ARRAY,
      productLoading: false,
      scan: '',
    }
  },

  mounted() {
    this.getWarehouseStorage()
  },

  methods: {

    massThermalPrint() {
      let data = [{id: this.MR.data.Order.getId()}]

      this.$store.dispatch('meestMassThermalPrint', {return_documents: data}).then((response) => {
        let data = this.getRespData(response)
        this.globalOpenPdfBase64(data?.fileContentBase64)
      })
    },

    changeScanner(val){
      console.log(val);
      let added = false
      if(val.length > 0){
        console.log(this.MR.data.productsForCellArray);
        this.MR.data.productsForCellArray.map(item => {
          console.log(item.data.scannedCount, item.data.productCount);
          if(added || item.data.scannedCount === item.data.productCount) return
          console.log(item.data.Product.product.productObject.upc_code);
          if(item.data.Product.product.productObject.upc_code === val || item.data.Product.product.productObject.sku_code === val){
            item.addScannedProduct()
            added = true
          }
        })
      }

      this.scan = ''
    },

    addDefaultProduct(){
      this.MR.addProduct()
    },

    changeWarehouse(val) {
      this.MR.data.Order.setWarehouse(val)
      this.getProducts().then(() => {
        console.log(222);
        this.MR.data.productsForCellArray.map((item, index) => {
          let product = this._.find(this.initialOptionsProducts, {id: item.data.Product.product.productObject?.id})
          if(product) {
            this.MR.data.productsForCellArray[index].data.Product.setProductObject(product)
          }
        })
      })
    },

    getWarehouseStorage(withoutProducts = false) {

      this.$store.dispatch('fetchWarehouseStorage').then((response) => {
        this.warehouses = this.getRespPaginateData(response)

        if(this.warehouses.length === 0) return

        // if not selected warehouse
        if(!this.MR.data.Order.data.warehouse) {
          this.MR.data.Order.setWarehouse(this._.first(this.warehouses))
        }

        // if selected warehouse not found in received warehouses
        if(!this._.find(this.warehouses, {id: this.MR.data.Order.data.warehouse?.id})) {
          this.MR.data.Order.setWarehouse(this._.first(this.warehouses))
        }

        if(withoutProducts) return

        this.getProducts()
      })
    },

    async getProducts() {
      this.productLoading = true
      if(!this.MR.data.User.userId) return

      const query = new this.Query();
      let url = '?';
      let myQuery = query
          .for('posts')

      if (this.isAdmin) {
        myQuery.where('ProductUserId', parseInt(this.MR.data.User.userId))
      }

      myQuery.where('byStorageId', this.MR.data.Order.data.warehouse?.id)

      myQuery.sort(`ProductName`)

      myQuery.where('ProductInDraft', '0')

      url = url + myQuery.limit(100000).page(parseInt(0)).url().split('?')[1]

      let typeFetch = 'fetchProducts'

      return this.$store.dispatch(typeFetch, url).then((response) => {
        this.initialOptionsProducts = this.getRespPaginateData(response)
        this.productLoading = false
      })
    },

    removeProduct(products, index) {
      this.MR.removeProductForCells(products, index)
    },

    changeProductQuantity(item, val) {
      item.setProductCount(val)
    },

    changeProductName(item, val) {
      item.setProduct(val)

      if(!val) return

      // SET item_quantity AND item_price from Proform product data
      this.$store.dispatch('getProducts', val.id + '?storageId=' + this.MR.data.Order.data.warehouse?.id).then((response)=>{
        let data = this.getRespData(response)
        if(data.proformData !== []){
          item.setProductCount(1)
        } else {
          item.setProductCount(0)
        }

        if (data?.count_in_storage?.count_normal <= 0) {
          this.openNotify('warn', 'fbm_ProductMissingWarning')
        }
      }).catch(error => this.createErrorLog(error))
    },

    changeUser(val){
      this.MR.data.User.setUser(val)
    },

    changeStatus(val) {
      this.MR.data.Order.setStatus(val)
    },

  },

}
</script>

<style scoped>

.loading-products{
  opacity: 0.5;
  pointer-events: none;
}
</style>