<template>
  <MeestReturnsEditingSection
          :MR="MR"
          :createData="createData"
          @save="save"
  />
</template>

<script>
  import {MeestReturns} from "../../models/MeestReturns";
  import MeestReturnsEditingSection from "./MeestReturnsEditingSection/MeestReturnsEditingSection";
  import {orderMeestMixin} from "@/mixins/orderMeestMixins/orderMeestMixin";
  import {MEEST_STATUSES} from "@/staticData/staticVariables";

  export default {
    name: "MeestReturnsEditing",

    components:{
      MeestReturnsEditingSection,
    },

    mixins: [orderMeestMixin],

    data() {
      return {
        MR: new MeestReturns(),
        transactionUser: {},
        createData: null,
      }
    },

    mounted() {
      this.initMeestReturnsEdit()
    },

    methods: {


      initMeestReturnsEdit() {

        this.MR.data.Order.setId(this.$route.params.id)

        this.$store.dispatch('getReturnGoodsDocuments', this.MR.data.Order.getId()).then((response) => {
          let item = this.getRespData(response)
          this.MR.setItem(item)


          if(this.loadUserAuthorizedData) {
            if(!this.isAdmin && this.MR.data.Order.getStatus()?.value !== MEEST_STATUSES.MEEST_STATUS_IN_PROGRESS.value) {
              this.$router.push(this.$store.getters.GET_PATHS.notFound)
            }
          }

        }).catch(error => this.createErrorLog(error))
      },

    }

  }
</script>

<style scoped>

</style>
