<template>
  <MeestReturnsCreationSection
          :MR="MR"
          :createData="createData"
          @save="save"
  />
</template>

<script>
  import {MeestReturns} from "../../models/MeestReturns";
  import MeestReturnsCreationSection from "./MeestReturnsCreationSection/MeestReturnsCreationSection";
  import {orderMeestMixin} from "@/mixins/orderMeestMixins/orderMeestMixin";

  export default {
    name: "MeestReturnsCreation",

    components:{
      MeestReturnsCreationSection,
    },

    mixins: [orderMeestMixin],

    watch: {
      getCurrentUser() {
        if(this.getCurrentUser && !this.isAdmin) {
          this.MR.data.User.setUser(this.getCurrentUser)
        }
      },
    },

    data() {
      return {
        MR: new MeestReturns(),
        transactionUser: {},
        createData: null,
      }
    },

    mounted() {
      if(this.getCurrentUser && !this.isAdmin) {
        this.MR.data.User.setUser(this.getCurrentUser)
      }
    },

    methods: {



    }

  }
</script>

<style scoped>

</style>
